import React, { useState } from 'react';
import styled from 'styled-components';

const Button = styled.button`
	color = "#ff00ff";
`;

const ButtonToggle = styled(Button)`
  opacity: 0.6;
  ${({ active }) =>
    active &&
    `
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: block;
`;
const DynamicTimeList = styled.div`
  display: flex;
  flex-direction: column
`
function addMinsToDate(objDate, toAdd) {
	var added = new Date(objDate.getTime() + toAdd*60000);
	return ('0'+added.getHours()).slice(-2) + ":" + ('0'+added.getMinutes()).slice(-2);
}
const button_mode = ['sleep now', 'wake up at...'];

function TimeInput() {
  const [inputValue, setInputValue] = useState('');
  const [selectedTime, setSelectedTime] = useState(null);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);

    // Parse the input value into a Date object
    const timeParts = value.split(':');
    if (timeParts.length === 2) {
      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);

      if (!isNaN(hours) && !isNaN(minutes)) {
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        setSelectedTime(date);
      } else {
        setSelectedTime(null);
      }
    } else {
      setSelectedTime(null);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="HH:MM"
        value={inputValue}
        onChange={handleInputChange}
      />
      {selectedTime && (
        <p>
		<div>
			you should try to fall asleep at one of the following times:
			<div>
				{addMinsToDate(selectedTime, -270)} for 3 cycles, or 4:30 of sleep
			</div>	
			<div>
				{addMinsToDate(selectedTime, -360)} for 4 cycles or 6:00 of sleep
			</div>	
			<div>
				{addMinsToDate(selectedTime, -450)} for 5 cycles or 7:30 of sleep
			</div>	
			<div>
				{addMinsToDate(selectedTime, -540)} for 6 cycles or 9:00 of sleep
			</div>	
			<h6>
				<i> keep in mind that you should be falling asleep at these times. 
					the average human takes 14 minutes to fall asleep, so plan accordingly!</i>
			</h6>		
		</div>
        </p>
      )}
    </div>
  );
}


function ToggleGroup({time}) {
  	const [active, setActive] = useState(button_mode[0]);
	const sleepnow = (
		<div>
			if you head to bed now, plan to wake up at:	
			<div>
				{addMinsToDate(time, 14+90)} for 1 cycle or 1h30m of sleep
			</div>	
			<div>
				{addMinsToDate(time, 14+180)} for 2 cycles, or 3h of sleep
			</div>	
			<div>
				{addMinsToDate(time, 14+270)} for 3 cycles, or 4h30m of sleep
			</div>	
			<div>
				{addMinsToDate(time, 14+360)} for 4 cycles or 6h of sleep
			</div>	
			<div>
				{addMinsToDate(time, 14+450)} for 5 cycles or 7h30m of sleep
			</div>	
			<div>
				{addMinsToDate(time, 14+540)} for 6 cycles or 9h of sleep
			</div>	
			<h6>
				<i>the average human takes 14 minutes to fall asleep, and this has been factored into our calculations!</i>
			</h6>		
		</div>
	);
	const wakeupat= (
		<TimeInput>
		</TimeInput>
	);
	return (
    <ButtonGroup>
		{button_mode.map(type => (
        <ButtonToggle
        	key={type}
          	active={active === type}
          	onClick={() => setActive(type)}
        >
          {type}
        </ButtonToggle>
      	))}
		<DynamicTimeList>
			{active === 'sleep now' ? sleepnow : wakeupat}
		</DynamicTimeList>
	</ButtonGroup>
  );
}

export default ToggleGroup