import './App.css';
import ToggleGroup from './components/ToggleGroup'
function App() {
  const date = new Date()
  const timeZone = String(Intl.DateTimeFormat().resolvedOptions().timeZone).split("/")[1].split("_").join(" ");
  
  return (
    <div className="App">
      <header className="App-header">
        <p align="center">
		      the time in {timeZone} is {('0'+date.getHours()).slice(-2) + ':' + ('0'+date.getMinutes()).slice(-2)}
        </p>
        <ToggleGroup
		time={date}
		/>
		<p align="center">
          welcome to the back porch
        </p>
      </header>
    </div>
  );
}

export default App;
